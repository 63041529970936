<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :needBtnLoading="true" :form-item-list="formItemList" :inline="true" :labelWidth="90" round
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <!-- <el-form-item class="el_from" label="部署公司" slot="merchId" style="margin: 0" label-width="75px">
        <el-select v-model="companyId" placeholder="请输入或选择部署公司" filterable clearable>
          <el-option v-for="item in merchSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item> -->
      <div slot="AddSlot">
        <el-button size="small" type="primary" @click="toAddMerchant">+ 添加部署</el-button>
      </div>
    </GlobalForm>
    <!-- 添加部署商户 -->
    <el-dialog title="添加部署商户" :visible.sync="EnableDialogVisible" :close-on-click-modal="false" width="700px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div>
          <p style="font-weight: bold;font-size: 15px;">部署信息：</p>
          <el-form-item label="部署公司/渠道名称:" label-width="145px" prop="deployCompanyName">
            <el-input v-model="ruleForm.deployCompanyName" placeholder="请输入部署公司/渠道名称"></el-input>
          </el-form-item>
        </div>
        <div>
          <p style="font-weight: bold;font-size: 15px;">小程序配置：</p>
          <el-form-item label="小程序名称:" label-width="110px" prop="wechatMiniProgramName">
            <el-input v-model="ruleForm.wechatMiniProgramName" placeholder="请输入小程序名称"></el-input>
          </el-form-item>
          <el-form-item label="小程序AppID:" label-width="110px" prop="wechatMiniAppId">
            <el-input v-model="ruleForm.wechatMiniAppId" placeholder="请输入小程序AppID"></el-input>
          </el-form-item>
          <el-form-item label="小程序secret:" label-width="110px" prop="wechatMiniAppSecret">
            <el-input v-model="ruleForm.wechatMiniAppSecret" placeholder="请输入小程序secret"></el-input>
          </el-form-item>
          <el-form-item label="小程序原始ID:" label-width="110px" prop="miniOriId">
            <el-input v-model="ruleForm.miniOriId" placeholder="请输入小程序secret"></el-input>
          </el-form-item>
        </div>
        <div>
          <p style="font-weight: bold;font-size: 15px;">公众号配置：</p>
          <el-form-item label="公众号名称:" label-width="110px" prop="wechatSubscribeName">
            <el-input v-model="ruleForm.wechatSubscribeName" placeholder="请输入公众号名称"></el-input>
          </el-form-item>
          <el-form-item label="公众号AppID:" label-width="110px" prop="wechatSubscribeAppId">
            <el-input v-model="ruleForm.wechatSubscribeAppId" placeholder="请输入公众号AppID"></el-input>
          </el-form-item>
          <el-form-item label="公众号secret:" label-width="110px" prop="wechatSubscribeAppSecret">
            <el-input v-model="ruleForm.wechatSubscribeAppSecret" placeholder="请输入公众号secret"></el-input>
          </el-form-item>
          <el-form-item label="公众号原始ID:" label-width="110px" prop="subscribeOriId">
            <el-input v-model="ruleForm.subscribeOriId" placeholder="请输入公众号secret"></el-input>
          </el-form-item>
        </div>
        <div>
          <p style="margin-bottom: 10px;font-weight: bold;font-size: 15px;">短信配置(非必填)：</p>
          <el-form-item label="短信签名:" prop="smsSign">
            <el-input v-model="ruleForm.smsSign" placeholder="请输入短信签名"></el-input>
          </el-form-item>
          <el-form-item label="短信账号:" prop="smsAccount">
            <el-input v-model="ruleForm.smsAccount" placeholder="请输入短信账号"></el-input>
          </el-form-item>
          <el-form-item label="短信密钥:" prop="smsSecretKey">
            <el-input v-model="ruleForm.smsSecretKey" placeholder="请输入短信密钥"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button size="medium" @click="resetForm('ruleForm')">取消</el-button>
          <el-button size="medium" type="primary" @click="submitForm('ruleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      EnableDialogVisible: false,
      initData: null,
      companyId: "",
      problemDialogVisible: false,
      merchSelectList: [],
      formItemList: [
        {
          key: "deployCompanyName",
          type: "input",
          labelName: "部署公司",
          placeholder: "输入部署公司",
        },
        {
          key: "wechatMiniProgramName",
          type: "input",
          labelName: "小程序名称",
          placeholder: "输入小程序名称",
        },
      ],
      ruleForm: {
        deployCompanyName: "",//部署公司名称
        smsAccount: '',//短信账号			
        smsSecretKey: '',//短信密钥			
        smsSign: '',//短信签名
        miniOriId: "",//小程序原始id
        subscribeOriId: "",	//公众号原始id
        wechatMiniAppId: '',//微信小程序appId			
        wechatMiniAppSecret: '',//微信小程序secret			
        wechatMiniProgramName: '',//微信小程序名称			
        wechatSubscribeAppId: '',//微信公众号appId			
        wechatSubscribeAppSecret: '',//微信公众号secret			
        wechatSubscribeName: '',	//微信公众号名称
      },
      rules: {
        deployCompanyName: [
          { required: true, message: '请输入部署公司/渠道名称', trigger: 'blur' },
        ],
        wechatMiniAppId: [
          { required: true, message: '请输入微信小程序appId', trigger: 'blur' },
        ],
        wechatMiniAppSecret: [
          { required: true, message: '请输入微信小程序secret', trigger: 'blur' },
        ],
        miniOriId: [
          { required: true, message: '请输入微信小程序原始id', trigger: 'blur' },
        ],
        wechatMiniProgramName: [
          { required: true, message: '请输入微信小程序名称', trigger: 'blur' },
        ],
        wechatSubscribeAppId: [
          { required: true, message: '请输入微信公众号appId', trigger: 'blur' },
        ],
        wechatSubscribeAppSecret: [
          { required: true, message: '请输入微信公众号secret', trigger: 'blur' },
        ],
        subscribeOriId: [
          { required: true, message: '请输入微信公众号原始id', trigger: 'blur' },
        ],
        wechatSubscribeName: [
          { required: true, message: '请输入微信公众号名称', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    // this.getSelectList()
    this.handleConfirm();
  },
  methods: {
    handleConfirm(data,cd) {
      let SeachParams = {};
      console.log(data);
      if (data) {
        SeachParams = {
          deployCompanyName: data.deployCompanyName,
          wechatMiniProgramName: data.wechatMiniProgramName,
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams.disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        }
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    toAddMerchant() {
      this.EnableDialogVisible = true
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.EnableDialogVisible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          //添加部署
          _api.addSlefcompany(this.ruleForm).then(res => {
            this.EnableDialogVisible = false
            this.$refs[formName].resetFields();
            this.$message({
              message: '添加成功！',
              type: 'success'
            });
            this.handleConfirm();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 所属商家下拉
    getSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.merchSelectList = res.data
        }
      })
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {}
</style>
