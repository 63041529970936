<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="启用状态" slot="isEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch @change="actionEnable(row)" v-model="row.isEnable">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column width="305" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="text" round @click="eddBtn(row.faceCode)">资源配置</el-button>
          <el-button size="mini" type="text" round @click="storeSction(row.deployId)">门店后台配置</el-button>
          <el-button size="mini" type="text" round @click="toAdvantage(row)">部署信息</el-button>
          <el-button size="mini" style="color: #85ce61;" type="text" round
            @click="WeChatPublicConfig(row.faceCode)">公众号配置</el-button>
          <el-button size="mini" style="color: #e6a23c;" type="text" round @click="categoryIcon(row)">分类图标配置</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 分类图标操作提示 -->
    <el-dialog title="操作提示" :visible.sync="classDialogVisible" :close-on-click-modal="false" width="450px"
      @closed="classCancel">
      <ElImgUpload v-if="classDialogVisible" key="QRCode" @handleDelete="handleDelete" :exist-image="classtypeImg"
        @handleChange="classtypehandleChange" placeTxt="最多支持上传1张图片，大小不超过2M，格式为JPG或PNG,建议尺寸56*56px" />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="classCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="classConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 状态提示 -->
    <el-dialog title="操作提示" :visible.sync="EnableDialogVisible" :close-on-click-modal="false" width="450px"
      @closed="EnableClosed">
      <p style="color: #ff8080;margin-bottom: 25px;" v-if="EnableData.isEnable">说明：启用后，该商户独立部署的小程序、门店管理后台可正常登录使用。</p>
      <p style="color: #ff8080;margin-bottom: 25px;" v-else>说明：禁用后，该商户独立部署的小程序、门店管理后台无法登录及使用，已登录账号将自动退出登录。</p>
      <div class="mb-20" style="text-align: center;">
        确认要{{ EnableData.isEnable ? "开启" : "关闭" }}该商户的独立部署小程序、门店管理后台？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="EnableCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="EnableConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看部署商户 -->
    <el-dialog title="查看部署商户" :visible.sync="IndependentDialogVisible" :close-on-click-modal="false" width="700px">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div>
          <p style="font-weight: bold;font-size: 15px;">部署信息：</p>
          <el-form-item label="部署公司/渠道名称:" label-width="145px" prop="deployCompanyName">
            <el-input disabled v-model="ruleForm.deployCompanyName" placeholder="请输入部署公司/渠道名称"></el-input>
          </el-form-item>
        </div>
        <div>
          <p style="font-weight: bold;font-size: 15px;">小程序配置：</p>
          <el-form-item label="小程序名称:" label-width="110px" prop="wechatMiniProgramName">
            <el-input disabled v-model="ruleForm.wechatMiniProgramName" placeholder="请输入小程序名称"></el-input>
          </el-form-item>
          <el-form-item label="小程序AppID:" label-width="110px" prop="wechatMiniAppId">
            <el-input disabled v-model="ruleForm.wechatMiniAppId" placeholder="请输入小程序AppID"></el-input>
          </el-form-item>
          <el-form-item label="小程序secret:" label-width="110px" prop="wechatMiniAppSecret">
            <el-input disabled v-model="ruleForm.wechatMiniAppSecret" placeholder="请输入小程序secret"></el-input>
          </el-form-item>
          <el-form-item label="小程序原始ID:" label-width="110px" prop="miniOriId">
            <el-input disabled v-model="ruleForm.miniOriId" placeholder="请输入小程序secret"></el-input>
          </el-form-item>
        </div>
        <div>
          <p style="font-weight: bold;font-size: 15px;">公众号配置：</p>
          <el-form-item label="公众号名称:" label-width="110px" prop="wechatSubscribeName">
            <el-input disabled v-model="ruleForm.wechatSubscribeName" placeholder="请输入公众号名称"></el-input>
          </el-form-item>
          <el-form-item label="公众号AppID:" label-width="110px" prop="wechatSubscribeAppId">
            <el-input disabled v-model="ruleForm.wechatSubscribeAppId" placeholder="请输入公众号AppID"></el-input>
          </el-form-item>
          <el-form-item label="公众号secret:" label-width="110px" prop="wechatSubscribeAppSecret">
            <el-input disabled v-model="ruleForm.wechatSubscribeAppSecret" placeholder="请输入公众号secret"></el-input>
          </el-form-item>
          <el-form-item label="公众号原始ID:" label-width="110px" prop="subscribeOriId">
            <el-input disabled v-model="ruleForm.subscribeOriId" placeholder="请输入公众号secret"></el-input>
          </el-form-item>
        </div>
        <div>
          <p style="margin-bottom: 10px;font-weight: bold;font-size: 15px;">短信配置(非必填)：</p>
          <el-form-item label="短信签名:" prop="smsSign">
            <el-input :disabled="flagsmsSign" v-model="ruleForm.smsSign" placeholder="请输入短信签名"></el-input>
          </el-form-item>
          <el-form-item label="短信账号:" prop="smsAccount">
            <el-input :disabled="flagsmsAccount" v-model="ruleForm.smsAccount" placeholder="请输入短信账号"></el-input>
          </el-form-item>
          <el-form-item label="短信密钥:" prop="smsSecretKey">
            <el-input :disabled="flagsmsSecretKey" v-model="ruleForm.smsSecretKey" placeholder="请输入短信密钥"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button size="medium" @click="resetFrom('ruleForm')">取消</el-button>
          <el-button size="medium" type="primary" @click="submitForm('ruleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 门店后台配置 -->
    <el-dialog title="门店后台配置" :visible.sync="StorebackgroundDialogVisible" :close-on-click-modal="false" width="700px"
      @closed="remarkClosed">
      <el-form :model="initData" :rules="formRules" ref="intinForm" label-width="100px" class="demo-ruleForm">
        <!-- :disabled="isFlag" isFlag是否编辑  -->
        <el-form-item label="备案号:" label-width="120px" prop="icp">
          <el-input v-model="initData.icp" placeholder="请输入备案号"></el-input>
        </el-form-item>
        <el-form-item label="版权信息:" label-width="120px" prop="copyright">
          <el-input v-model="initData.copyright" placeholder="请输入版权信息"></el-input>
        </el-form-item>
        <el-form-item label="详细信息:" label-width="120px" prop="detailInfo">
          <el-input v-model="initData.detailInfo" placeholder="请输入详细信息"></el-input>
        </el-form-item>
        <el-form-item label="后台名称:" label-width="120px" prop="storeBgName">
          <el-input v-model="initData.storeBgName" placeholder="请输入后台名称"></el-input>
        </el-form-item>
        <el-form-item label="门店后台域名:" label-width="120px" prop="domain">
          <el-input :disabled="isFlag" v-model="initData.domain" placeholder="请输入门店后台域名"></el-input>
        </el-form-item>
        <el-form-item label="左背景图:" prop="leftBgImg" label-width="120px">
          <ElImgUpload key="QRCode" :exist-image="initData.leftBgImg" @handleChange="lefthandleChange"
            placeTxt="最多支持上传1张图片，大小不超过2M，格式为JPG或PNG,建议尺寸100*100px" />
        </el-form-item>
        <el-form-item label="右背景图:" prop="rightBgImg" label-width="120px">
          <ElImgUpload key="QRCode" :exist-image="initData.rightBgImg" @handleChange="righthandleChange"
            placeTxt="最多支持上传1张图片，大小不超过2M，格式为JPG或PNG,建议尺寸100*100px" />
        </el-form-item>
        <el-form-item label="门店后台logo:" label-width="120px" prop="storePcLoginImage">
          <ElImgUpload key="QRCode" :exist-image="initData.storePcLoginImage" @handleChange="logohandleChange"
            placeTxt="最多支持上传1张图片，大小不超过2M，格式为JPG或PNG,建议尺寸100*100px" />
        </el-form-item>
        <el-form-item>
          <el-button size="medium" @click="closeForm('intinForm')">取消</el-button>
          <el-button size="medium" type="primary" @click="storesubmitForm('intinForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--分类图标配置-->
    <el-dialog :title="'分类图标配置-' + recorderListCopy.wechatMiniProgramName" :visible.sync="dlogincodeVisible"
      :close-on-click-modal="false" width="800px" @closed="dlogincodeVisible = false; postponeDataList = []">
      <GlobalTable ref="GlobalTable" :columns="postponeTableColumns" :data="postponeDataList" :isPagination="false">
        <el-table-column label="分类" slot="modelType" align="center">
          <template slot-scope="{ row }">
            <span>
              {{ row.typeName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="选中分类图标" slot="typeIconed" align="center">
          <template slot-scope="{ row }">
            <img class="typeimg" :src="row.selectIcon" alt="">
            <p @click="updatecalssImage(row, 'selected')" style="color: red;text-decoration: underline;cursor: pointer;">
              更换
            </p>
          </template>
        </el-table-column>
        <el-table-column label="未选中分类图标" slot="notypeIconed" align="center">
          <template slot-scope="{ row }">
            <img class="typeimg" :src="row.unSelectIcon" alt="">
            <p @click="updatecalssImage(row, 'noselect')" style="color: red;text-decoration: underline;cursor: pointer;">
              更换
            </p>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click.native="dlogincodeVisible = false; postponeDataList = []">取消</el-button>
        <el-button size="small" type="primary" @click.native="extensionSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "@/components/global/components/elements/upload-element";
import _api from "@/utils/request";
export default {
  name: "Table",
  components: { ElImgUpload },
  data() {
    return {
      iconData: {},
      iconType: "",
      classtypeImg: "",
      classDialogVisible: false,
      postponeDataList: [],
      postponeTableColumns: [
        { slotName: "modelType" },
        { slotName: "typeIconed" },
        { slotName: "notypeIconed" },
      ],
      dlogincodeVisible: false,
      isFlag: false,
      isResetdata: false,
      flagsmsAccount: '',//短信账号			
      flagsmsSecretKey: '',//短信密钥			
      flagsmsSign: '',//短信签名		
      ruleForm: {
        deployCompanyName: "",//部署公司名称
        smsAccount: '',//短信账号			
        smsSecretKey: '',//短信密钥			
        smsSign: '',//短信签名		
        wechatMiniAppId: '',//微信小程序appId			
        wechatMiniAppSecret: '',//微信小程序secret			
        wechatMiniProgramName: '',//微信小程序名称			
        wechatSubscribeAppId: '',//微信公众号appId			
        wechatSubscribeAppSecret: '',//微信公众号secret			
        wechatSubscribeName: '',	//微信公众号名称
      },
      loading: false,
      StorebackgroundDialogVisible: false,
      deployId: "",
      initData: {
        icp: "",//备案号
        copyright: '',//版权信息			
        detailInfo: '',//详细信息			
        domain: '',//门店后台域名		
        leftBgImg: '',//门店-左背景图			
        rightBgImg: '',//门店-右背景图			
        storeBgName: '',//门店后台名称			
        storePcLoginImage: '',//门店后台图片			
      },
      formRules: {
        copyright: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入版权信息'
          },
        ],
        detailInfo: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入详细信息'
          },
        ],
        domain: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入门店后台域名'
          },
        ],
        icp: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入备案号'
          },
        ],
        storeBgName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入门店后台名称'
          },
        ],
        leftBgImg: [
          {
            required: true,
            trigger: 'blur',
            message: '请上传门店-左背景图'
          },
        ],
        rightBgImg: [
          {
            required: true,
            trigger: 'blur',
            message: '请上传门店-右背景图'
          },
        ],
        storePcLoginImage: [
          {
            required: true,
            trigger: "blur",
            message: "请上传门店后台图片"
          }
        ],
      },
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      EnableTitle: "",
      EnableDialogVisible: false,
      IndependentDialogVisible: false,
      EnableData: {
        id: "",
        isEnable: "",
      },
      remarkData: {
        id: "",
        remark: "",
      },
      delDialogVisible: false,
      remarkTitle: "",
      delFromData: {
        command: "",
        id: "",
      },
      tableData: {
        companyName: "",
        name: "",
      },
      remarkDialogVisible: false,
      tableColumns: [
        { label: "独立部署公司/渠道", prop: "deployCompanyName" },
        { label: "小程序名称", prop: "wechatMiniProgramName" },
        { label: "公众号名称", prop: "wechatSubscribeName" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "isEnable" },
        { slotName: "operation" },
      ],
      recorderListCopy: {}
    };
  },
  created() {
    this.handleCurrentChange();
    console.log(document.domain);
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    //取消更换
    classCancel() {
      this.classtypeImg = ""
      this.classDialogVisible = false
    },
    handleDelete(e) {
      this.classtypeImg = ""
    },
    //确认更换
    classConfirmDialog() {
      if (this.iconType === 'selected') {
        this.iconData.selectIcon = this.classtypeImg
      } else {
        this.iconData.unSelectIcon = this.classtypeImg
      }
      console.log(this.iconData);
      //更新列表
      this.postponeDataList.forEach((itm, index) => {
        if (itm.typeId == this.iconData.typeId) {
          this.postponeDataList[index] = { ...this.iconData }
        }
      })
      console.log(this.postponeDataList);
      this.classDialogVisible = false
    },
    //点击更换
    updatecalssImage(row, type) {
      console.log(row);
      this.iconData = row
      this.iconType = type
      this.classtypeImg = type === 'selected' ? row.selectIcon : row.unSelectIcon
      this.classDialogVisible = true
    },
    extensionSuccess() {
      _api.saveTypeIconList({
        "id": this.recorderListCopy.deployId,
        "list": this.postponeDataList
      }).then(res => {
        if (res.code == 1) {
          this.$message({
                message: '配置成功！',
                type: 'success'
              });
          this.recorderListCopy = {}
          this.dlogincodeVisible = false
          this.handleCurrentChange();
        }
      })
    },
    //分类图标配置
    categoryIcon(row) {
      console.log(row);
      this.recorderListCopy = row
      _api.getTypeIconList({
        id: row.deployId
      }).then(res => {
        this.dlogincodeVisible = true
        this.postponeDataList = res.data
      })

    },
    closeForm(formName) {
      this.$refs[formName].resetFields();
      this.StorebackgroundDialogVisible = false
    },
    //门店后台设置
    storesubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.initData);
          if (this.isFlag) {
            //编辑部署
            _api.editStorePCConfig({ id: this.deployId, ...this.initData }).then(res => {
              this.StorebackgroundDialogVisible = false
              this.$refs[formName].resetFields();
              this.$message({
                message: '设置成功！',
                type: 'success'
              });
              this.handleCurrentChange();
            })
          } else {
            //添加部署
            _api.setStorePCConfig({ id: this.deployId, ...this.initData }).then(res => {
              this.StorebackgroundDialogVisible = false
              this.$refs[formName].resetFields();
              this.$message({
                message: '设置成功！',
                type: 'success'
              });
              this.handleCurrentChange();
            })
          }
        } else {
          console.log('error submit!');
          return false;
        }
      });
    },
    classtypehandleChange(e) {
      console.log(e);
      this.classtypeImg = e
    },
    lefthandleChange(e) {
      this.initData.leftBgImg = e
    },
    righthandleChange(e) {
      this.initData.rightBgImg = e
    },
    logohandleChange(e) {
      this.initData.storePcLoginImage = e
    },
    submitForm() {
      if (!this.flagsmsAccount || !this.flagsmsSecretKey || !this.flagsmsSign) {
        _api.editshortMessage({
          "deployId": this.ruleForm.deployId,
          "smsAccount": this.ruleForm.smsAccount,
          "smsSecretKey": this.ruleForm.smsSecretKey,
          "smsSign": this.ruleForm.smsSign
        }).then(res => {
          this.$message({
            message: '保存成功！',
            type: 'success'
          });
          this.handleCurrentChange();
          this.IndependentDialogVisible = false
        })
      } else {
        this.handleCurrentChange();
        this.IndependentDialogVisible = false
      }

    },
    resetFrom(formName) {
      this.$refs[formName].resetFields();
      this.IndependentDialogVisible = false
      this.handleCurrentChange();
    },
    //后台配置
    storeSction(id) {
      this.deployId = id
      _api.getindependentinfo({ deployId: id }).then(res => {
        if (res.code == 1) {
          console.log(res.data.icp);
          if (res.data.icp) {
            this.isFlag = true
            this.initData = res.data
          } else {
            this.isFlag = false
            this.initData = {
              icp: "",//备案号
              copyright: '',//版权信息			
              detailInfo: '',//详细信息			
              domain: '',//门店后台域名		
              leftBgImg: '',//门店-左背景图			
              rightBgImg: '',//门店-右背景图			
              storeBgName: '',//门店后台名称			
              storePcLoginImage: '',//门店后台图片			
            }
          }
          this.StorebackgroundDialogVisible = true
        }
      })

    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        deployCompanyName: this.SeachParams.deployCompanyName,
        wechatMiniProgramName: this.SeachParams.wechatMiniProgramName,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.getslefBdepdatalist(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
      });
    },
    // 跳转商家列表
    toStoreShop(companyName) {
      let routeData = this.$router.resolve({
        path: "/StoreShop/index",
        query: { companyName },
      });
      window.open(routeData.href, "_blank");
    },
    // 修改状态
    EnableClosed() {
      this.EnableDialogVisible = false;
      this.handleCurrentChange();
    },
    actionEnable(e) {
      this.EnableDialogVisible = true;
      this.EnableData.id = e.deployId;
      this.EnableData.isEnable = e.isEnable;
    },
    EnableCancel() {
      this.EnableDialogVisible = false;
      this.handleCurrentChange();
    },
    EnableConfirmDialog() {
      _api.getslefsetEnable(this.EnableData).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.EnableDialogVisible = false;
        this.handleCurrentChange();
      });
    },
    // 备注说明
    remarkClosed() {
      this.StorebackgroundDialogVisible = false;
      this.isResetdata = true
    },
    remarkClick(row) {
      this.remarkData.id = row.id;
      this.remarkData.remark = row.remark;
      this.remarkTitle = row.remark ? "修改备注" : "添加备注";
      this.remarkDialogVisible = true;
    },
    remarkConfirmDialog() {
      _api.updateRemark(this.remarkData).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.remarkDialogVisible = false;
        this.handleCurrentChange();
      });
    },
    // 资源配置
    eddBtn(faceCode) {
      const { href } = this.$router.resolve({
        path: "/IndependentManagement/Resources",
        query: {
          faceCode
        },
      });
      window.open(href, "_blank");
    },
    // 公众号配置
    WeChatPublicConfig(faceCode) {
      const { href } = this.$router.resolve({
        path: "/IndependentManagement/WeChatPublicConfig",
        query: {
          faceCode
        },
      });
      window.open(href, "_blank");
    },
    // 部署信息
    toAdvantage(row) {
      this.ruleForm = row
      this.flagsmsAccount = this.ruleForm.smsAccount ? true : false//短信账号			
      this.flagsmsSecretKey = this.ruleForm.smsSecretKey ? true : false//短信密钥			
      this.flagsmsSign = this.ruleForm.smsSign ? true : false//短信签名		
      this.IndependentDialogVisible = true
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }

  .redColor {
    color: red;
  }
}

.typeimg {
  width: 30px;
  height: 40px;
}
</style>
